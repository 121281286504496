import React, { useState, useEffect } from "react";
import RacingBarChart from "./RacingBarChart";
import useKeyframes from "./useKeyframes";
import useWindowSize from "./useWindowSize";
import Categories from "./Categories";

const dataUrl = "/data/Comp9Likes2.csv";
const numOfBars = 8;
const numOfSlice = 1;
const chartMargin = {
  top: 30,
  right: 10,
  bottom: 30,
  left: 0,
};

function App() {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const chartWidth = windowWidth;
  // 44px to account for 35px button height, 5px button margin-bottom
  // and 4px from unknown
  const chartHeight = windowHeight - 70;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const [compId, setCompId] = useState(9);
  const [compIda, setCompIda] = useState(params.get("id"));
  const [type, setType] = useState(params.get("type"));
  const [color, setColor] = useState(params.get("color"));
  const [category, setCategory] = useState("All");
  // console.log(compIda,type)
  var keyframes = useKeyframes(dataUrl, numOfSlice, compIda, type);
  const chartRef = React.useRef();
  if (color === "b") {
    document.body.style.backgroundColor = "black";
  }
  const handleReplay = () => {
    chartRef.current.replay();
  };
  const HandleStart = () => {
    // keyframes = useKeyframes(dataUrl, numOfSlice, compId);
    chartRef.current.start();
  };
  const handleStop = () => {
    chartRef.current.stop();
  };
  const myChangeHandler = (event) => {
    setCompId(event.target.value);
  };
  const MySubmitHandler = (event) => {
    setCompIda(compId);
    setType(0);
  };

  const playing = chartRef.current ? chartRef.current.playing : false;
  const [_, forceUpdate] = useState();

  const categories = ["All"];

  keyframes.forEach((date) =>
    date.data.forEach((data) => {
      if (!categories.includes(data.category) && data.category !== "") {
        categories.push(data.category);
      }
    })
  );

  let newKeyframes = keyframes;

  if (category !== "All") {
    newKeyframes = keyframes.map((date) => {
      return {
        data: date.data.filter((data) => data.category === category),
        date: date.date,
      };
    });
    if (!playing) {
      handleReplay();
    }
  }

  return (
    <>
      {keyframes.length === 0 ? (
        <div className="loader-wrapper">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div style={{ margin: "0 0", overflow: "hidden" }}>
          <Categories
            categories={categories}
            category={category}
            setCategory={setCategory}
          />
          <div>
            {keyframes.length > 0 && (
              <RacingBarChart
                keyframes={newKeyframes}
                numOfBars={numOfBars}
                width={chartWidth}
                height={chartHeight}
                margin={chartMargin}
                onStart={() => forceUpdate(false)}
                onStop={() => forceUpdate(false)}
                ref={chartRef}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default App;
