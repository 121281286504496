import React from "react";
import { Bar as VxBar } from "@vx/shape";
import { Text } from "@vx/text";
import user from "./assets/entries.png";

const link = (chl, name) => {
  if (chl > 0) {
    window.location.href = "https://ios.8secondz.app/chl?id=" + chl;
  }
};

const Bar = ({
  color,
  x,
  y,
  width,
  height,
  name,
  value,
  avatar,
  xMax,
  challenge,
  username,
  category,
}) => {
  const text = `${name}`;
  const usernameText = `${username}`;
  const categoryText = `${category}`;
  const barValue = `${value}`;
  // const avar = `${competition}`;
  // console.log(avatar);
  return (
    <>
      <VxBar
        radius={3}
        x={x + 44}
        y={y + height - 10}
        width={width}
        height={16}
        fill={color}
        onClick={(event) => link(challenge, name)}
      />
      <foreignObject x={x + 50} y={y + height - 26} width={xMax} height="40">
        <div style={{ display: "flex" }}>
          <div
            style={{
              maxWidth: xMax / 3,
              display: "flex",
              alignItems: "center",
              minWidth: 0,
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "0.75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              width={xMax / 3}
              fill="#575757"
              onClick={(event) => link(challenge, name)}
            >
              @{usernameText}
            </span>
          </div>
          <span
            style={{
              margin: "0 5px",
              fontWeight: "500",
              fontSize: "0.75rem",
            }}
            fill="#575757"
            onClick={(event) => link(challenge, name)}
          >
            •
          </span>
          <div
            style={{
              maxWidth: xMax / 3,
              display: "flex",
              alignItems: "center",
              minWidth: 0,
            }}
          >
            <span
              style={{
                fontWeight: "500",
                fontSize: "0.75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={(event) => link(challenge, name)}
            >
              {text}
            </span>
          </div>
          <span
            style={{
              margin: "0 5px",
              fontWeight: "500",
              fontSize: "0.75rem",
            }}
            onClick={(event) => link(challenge, name)}
          >
            •
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f85d3b",
              padding: "0 10px",
              borderRadius: "10px",
              height: 14,
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: 8,
                color: "white",
                whiteSpace: "nowrap",
              }}
              onClick={(event) => link(challenge, name)}
            >
              {categoryText}
            </span>
          </div>
        </div>
      </foreignObject>
      <foreignObject x={width + 50} y={y + height - 10} width="15" height="15">
        <img x={10} y={y + height - 30} width="15" height="15" src={user} />
      </foreignObject>
      <Text
        style={{
          fontWeight: "700",
          fontSize: 12,
        }}
        x={width + 68}
        y={y + height + 3}
      >
        {barValue}
      </Text>
      <foreignObject x={10} y={y + height - 23} width="34" height="40">
        <img
          x={10}
          y={y + height - 30}
          width="34"
          height="40"
          style={{ fill: "red", borderRadius: 5 }}
          xlinkHref={avatar}
          src={avatar}
        />
      </foreignObject>
    </>
  );
};

export default Bar;
