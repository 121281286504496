import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { Text } from "@vx/text";

import { scaleLinear, scaleBand, scaleOrdinal } from "@vx/scale";
import { Group } from "@vx/group";
import RacingAxisTop from "./RacingAxisTop";
import RacingBarGroup from "./RacingBarGroup";
// import * as colors from "d3-scale-chromatic";

const colorScheme = [
  "#ffca3a",
  "#ff5964",
  "#AA90AA",
  "#235789",
  "#020122",
  "#7F6DF1",
  "#3A35BA",
  "#0C110F",
  "#964860",
  "#316395",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
]; //colors.schemeSet2;
const RacingBarChart = React.forwardRef(
  ({ numOfBars, width, height, margin, keyframes, onStart, onStop }, ref) => {
    const [{ frameIdx, animationKey, playing }, setAnimation] = useState({
      frameIdx: 0,
      animationKey: 0,
      playing: true,
    });
    const updateFrameRef = useRef();
    // when replay, increment the key to rerender the chart.
    useEffect(() => {
      if (!updateFrameRef.current) {
        updateFrameRef.current = setTimeout(() => {
          setAnimation(({ frameIdx: prevFrameIdx, playing, ...others }) => {
            const isLastFrame = prevFrameIdx === keyframes.length - 1;
            const nextFrameIdx = isLastFrame ? prevFrameIdx : prevFrameIdx + 1;
            return {
              ...others,
              frameIdx: playing ? nextFrameIdx : prevFrameIdx,
              playing: !!(playing && !isLastFrame),
            };
          });
          // console.log("updateFrameRef", updateFrameRef)
          updateFrameRef.current = null;
        }, 250);
      }
    });

    const barGroupRef = useRef();
    const axisRef = useRef();
    useImperativeHandle(ref, () => ({
      replay: () => {
        clearTimeout(updateFrameRef.current);
        updateFrameRef.current = null;
        setAnimation(({ animationKey, ...others }) => ({
          ...others,
          frameIdx: 0,
          animationKey: animationKey + 1,
          playing: true,
        }));
      },
      start: () => {
        setAnimation((animation) => ({
          ...animation,
          playing: true,
        }));
      },
      stop: () => {
        setAnimation((animation) => ({
          ...animation,
          playing: false,
        }));
        barGroupRef.current.stop();
        axisRef.current.stop();
      },
      playing,
    }));
    const prevPlayingRef = useRef(playing);
    useEffect(() => {
      if (prevPlayingRef.current !== playing) {
        if (playing) {
          onStart();
        } else {
          onStop();
        }
      }
      prevPlayingRef.current = playing;
    }, [playing]);
    useLayoutEffect(() => {
      if (barGroupRef.current !== null && barGroupRef !== null) {
        if (playing) {
          // console.log("bargroudpred", barGroupRef.current)
          barGroupRef.current.start();
          // axisRef.current.start();
        }
      }
    });
    const frame = keyframes[frameIdx];
    const { date: currentDate, data: frameData } = frame;
    const values = frameData.map(({ value }) => value);
    const xMax = width - margin.left - margin.right - 105;
    const yMax = height - margin.top - margin.bottom;
    const domainMax = Math.max(...values);
    const xScale = scaleLinear({
      domain: [0, domainMax],
      range: [0, xMax],
    });
    const yScale = useMemo(
      () =>
        scaleBand({
          domain: Array(numOfBars)
            .fill(0)
            .map((_, idx) => idx),
          range: [0, yMax],
        }),
      [numOfBars, yMax]
    );
    const nameList = useMemo(() => {
      if (keyframes.length === 0) {
        return [];
      }
      return keyframes[0].data.map((d) => d.name);
    }, [keyframes]);
    const colorScale = useMemo(
      () => scaleOrdinal(colorScheme).domain(nameList).range(colorScheme),
      [nameList]
    );
    // console.log("hello",frameData.slice(0, numOfBars))
    const dateFull = currentDate.toLocaleDateString("en-GB");
    return (
      <>
        <h1>Top Challenges</h1>
        <h3>What's hot right now on 8secondz</h3>
        <svg width={width - 20} height={height}>
          <Group top={-20} left={margin.left} key={animationKey}>
            <RacingBarGroup
              frameData={frameData.slice(0, numOfBars)}
              xScale={xScale}
              yScale={yScale}
              colorScale={colorScale}
              xMax={xMax}
              ref={barGroupRef}
            />
            {/* <RacingAxisTop domainMax={domainMax} xMax={xMax} ref={axisRef} /> */}
          </Group>
        </svg>
        {/* <p
          style={{
            position: "absolute",
            margin: 0,
            top: yMax + 65,
            right: 10,
            fontSize: 18,
            color: "#b5b5b5",
            textShadow:
              "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,1px 1px 0 #fff",
          }}
        >
          {dateFull}
        </p> */}
      </>
    );
  }
);

RacingBarChart.defaultProps = {
  width: 600,
  height: 450,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 100,
  },
};

export default RacingBarChart;
