import React from "react";
import axios from "axios";
import placeholder from "./assets/userPlaceholder.png";
// import csvParse from "csv-parse/lib/sync";
// import API from "./8seczService";

const buildFindData = (data) => {
  const dataByDateAndName = new Map();
  data.forEach((dataPoint) => {
    var { date, name, challenge, competition } = dataPoint;
    // console.log(dataPoint);
    date = new Date(date).toISOString().slice(0, 10);
    if (name === null) {
      name = "no name";
    }
    if (challenge === null) {
      challenge = -1;
    }
    if (competition === null) {
      competition = -1;
    }
    // if (avatar == null) {avatar = "https://via.placeholder.com/30"}
    if (!dataByDateAndName.get(date)) {
      dataByDateAndName.set(date, { [name]: dataPoint });
    } else {
      const nextGroup = {
        ...dataByDateAndName.get(date),
        [name]: dataPoint,
      };
      // console.log(nextGroup);
      dataByDateAndName.set(date, nextGroup);
    }
    // console.log(dataByDateAndName);
  });
  const finder = ({ date, name }) => {
    // console.log(date,name)
    try {
      // console.log("findData ok", date,name);
      return dataByDateAndName.get(date)[name];
    } catch (e) {
      // console.log("findData error", e);
      return null;
    }
  };
  return finder;
};

function sanitizeString(str) {
  str = str.toString().replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "");
  return str;
}

const makeKeyframes = (data, numOfSlice) => {
  /**
   * Assume data is an array of { date: string, name: string, value: number, ...others }.
   * This function return an array of keyframe, each keyframe is { date: Date, data: { name: string, value: number, ...others }[] }.
   * At first we will collect all of the name appearing in the original data.
   * The `data` field of keyframe is descending sorted by `value` field.
   */
  const findData = buildFindData(data);
  // console.log("raw data",data);
  const nameSet = new Set(data.map(({ name }) => name));
  const nameList = [...nameSet];
  // console.log(nameList)
  var latest = new Date(
    Math.max.apply(
      null,
      data.map(({ date }) => {
        return new Date(date);
      })
    )
  );
  var oldest = new Date(
    Math.min.apply(
      null,
      data.map(({ date }) => {
        return new Date(date);
      })
    )
  );
  // oldest = new Date("2019-10-07T23:00:00.000Z")
  // console.log("latest",latest);
  // console.log("oldest",oldest);
  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };
  var datesArray = getDaysArray(oldest, latest);
  var dateSet = new Set(
    datesArray.map((date) => date.toISOString().slice(0, 10))
  ); //new Set(data.map(({ date }) => date));
  var dateList = [...dateSet];
  // console.log(dateList)
  var prevDataPoint;
  // var test;
  var a = 0;
  var dataPoint;
  var frames = dateList.sort().map((date, dateIdx, dateArray) => {
    // test = date;
    // console.log(date);
    var result = {
      date,
      data: nameList.map((name) => {
        dataPoint = findData({ date, name });
        // have data for user here
        // console.log("dataPoint", dataPoint)
        // console.log("date",date)
        // console.log("name", name)
        if (dataPoint) {
          // console.log("dataPoint")
          // console.log("name", name)
          return {
            ...dataPoint,
          };
        } else {
          return null;
        }
      }),
    };
    // console.log("result",result)
    // Should be around here, this only checks for the first data
    // needs to check for individual user here. might be a problem with lots of users
    // problems with non-even user per day. Trips up here. The null check is the problem

    const goodResult = {
      date: result.date,
      data: result.data.map((element, index, array) => {
        if (element == null) {
          // console.log("element null ",element)
          if (prevDataPoint != null) {
            // console.log("prevdata", prevDataPoint)
            return prevDataPoint[index];
          } else {
            // return dummy data if there is no previous stuff
            // console.log("dummy data")
            a += 1;
            return {
              // category: '0',
              date: oldest,
              name: a,
              value: 0,
              avatar: "",
              challenge: -1,
              competition: -1,
            };
          }
        } else {
          // console.log("not null",element)
          return element;
        }
      }),
    };
    // console.log(goodResult)
    if (!goodResult.data.includes(null)) {
      prevDataPoint = goodResult.data;
      // console.log("good",prevDataPoint)
    } else {
      // console.log("no good")
      result.data = prevDataPoint;
      prevDataPoint = goodResult.data;
    }
    return goodResult;
  });
  // console.log("frames",frames)
  // set dates to the right one
  var filledFrames = frames.map((element, index, array) => {
    var res = element.data.map((e) => {
      var avatarUrl = "";
      var chal = 0;
      var comp = 0;
      var name = "";
      var username = "";
      var category = "";

      if (e.name === "" || e.name === null) {
        console.log("null name");
        name = "";
      } else {
        name = sanitizeString(e.name);
      }
      if (e.avatar === "" || e.avatar === null) {
        // console.log("null ava")
        avatarUrl = placeholder;
      } else {
        avatarUrl = "https://d1dljjxaor3r45.cloudfront.net/60x70" + e.avatar;
      }
      if (e.challenge === null || e.challenge === undefined) {
        console.log("null chal");
        chal = -1;
      } else {
        chal = e.challenge;
      }
      if (e.competition === null || e.competition === undefined) {
        // console.log("null comp")
        comp = -1;
      } else {
        comp = e.competition;
      }

      if (
        e.username === "" ||
        e.username === null ||
        e.username === undefined
      ) {
        // console.log("null username");
        username = "";
      } else {
        username = sanitizeString(e.username);
      }

      if (
        e.category === "" ||
        e.category === null ||
        e.category === undefined
      ) {
        // console.log("null category");
        category = "";
      } else {
        category = sanitizeString(e.category);
      }

      var temp = {
        date: element.date,
        name: name,
        value: e.value,
        avatar: avatarUrl,
        challenge: chal,
        competition: comp,
        username: username,
        category: category,
      };

      return temp;
    });
    var final = {
      date: element.date,
      data: res,
    };
    return final;
  });
  // console.log("filled Frames", filledFrames);
  const keyframes = filledFrames
    .reduce((result, frame, idx) => {
      const prev = frame;
      const next = idx !== frames.length - 1 ? frames[idx + 1] : null;
      if (!next) {
        result.push({ ...frame, date: new Date(frame.date) });
      } else {
        const prevTimestamp = new Date(prev.date).getTime();
        const nextTimestamp = new Date(next.date).getTime();
        const diff = nextTimestamp - prevTimestamp;
        for (let i = 0; i < numOfSlice; i++) {
          const sliceDate = new Date(prevTimestamp + (diff * i) / numOfSlice);
          const sliceData = frame.data.map(({ name, value, ...others }) => {
            const prevValue = value;
            // problem here!!!
            //const nextDataFull = filledFrames.find(element => element.date === next.date).data.find(element => element.name === name);
            const nextDataPoint = filledFrames
              .find((element) => element.date === next.date)
              .data.find((element) => element.name === name); //findData({ date: next.date, name });
            const nextValue = nextDataPoint ? nextDataPoint.value : 0;
            const sliceValue =
              prevValue + ((nextValue - prevValue) * i) / numOfSlice;
            return {
              name,
              value: sliceValue,
              ...others,
            };
          });
          result.push({
            date: sliceDate,
            data: sliceData,
          });
        }
      }
      return result;
    }, [])
    .map(({ date, data }) => {
      return {
        date,
        data: data.sort((a, b) => b.value - a.value),
      };
    });
  // console.log(keyframes);
  return keyframes;
};

function useKeyframes(dataUrl, numOfSlice, id, type) {
  const [keyframes, setKeyframes] = React.useState([]);
  React.useEffect(() => {
    if (!id) {
      id = 9;
    }

    var baseURL = "";
    if (type === "comp") {
      baseURL =
        "https://api.8secondz.in/v1/apps/8secondz/api/Competitions/" +
        id +
        "/chart";
    } else if (type === "chal") {
      baseURL =
        "https://api.8secondz.in/v1/apps/8secondz/api/Challenges/" +
        id +
        "/chart";
    } else {
      console.log("hi");
      baseURL =
        "https://api.8secondz.in/v1/apps/staging/api/Videos/globalChart";
    }
    axios
      .get(baseURL, {
        headers: {
          Authorization: `eYrpARayeHZ8nW7dqcdeOVtcF6YPspKWFx3qvBVWktF649QNmECrQDa7Qg0ydlUl`,
        },
      })
      .then((resp) => {
        const data = resp.data;
        // console.log(data);
        // const { data: csvString } = resp;
        // const nextData = csvParse(csvString)
        //   .slice(1)
        //   .map(([date, name, category, value]) => ({
        //     date,
        //     name,
        //     category,
        //     value: Number(value)
        //   }));
        const keyframes = makeKeyframes(data, numOfSlice);
        setKeyframes(keyframes);
      });
  }, [dataUrl, numOfSlice, id, type]);
  return keyframes;
}

export default useKeyframes;
