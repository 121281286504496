import React from "react";
import { Bar as VxBar } from "@vx/shape";
import { Text } from "@vx/text";



const HiddenBar = ({ color, x, y, width, height, name, value, avatar }) => {
  const text = `@${name}`;
  const barValue = `${value}`
  const avar = `${avatar}`;
  return (
    <React.Fragment>
    
           <VxBar
            x="0"
            y={y}
            width={width}
            height={height - 10}
            fill={color}
          />

    </React.Fragment>
  );
};
  export default  HiddenBar;