import React, { forwardRef } from "react";
import { useTransition, animated } from "react-spring";
import Bar from "./Bar";
import HiddenBar from "./HiddenBar";

const AnimatedBar = animated(Bar);
const HiddenAnimatedBar = animated(HiddenBar);

const RacingBarGroup = forwardRef(
  ({ frameData, xScale, yScale, colorScale, xMax }, ref) => {
    const transitions = useTransition(
      frameData.map(
        ({ name, value, avatar, challenge, username, category }, idx) => ({
          y: yScale(idx),
          width: xScale(value),
          xMax,
          value,
          name,
          avatar,
          challenge,
          username,
          category,
        })
      ),
      (d) => d.name,
      {
        initial: (d) => d,
        from: { y: yScale.range()[1] + 50, width: 0, value: 0 },
        leave: { y: yScale.range()[1] + 50, width: 0, value: 0 },
        enter: (d) => d,
        update: (d) => d,
        unique: true,
        ref,
      }
    );
    return transitions.map(({ item, props }) => {
      const { y, value, width } = props;
      const { name, avatar, challenge, xMax, username, category } = item;
      if (Number.isInteger(name)) {
        return (
          <HiddenAnimatedBar
            x={xScale(0)}
            y={y}
            width={width}
            height={yScale.bandwidth()}
            color={colorScale(name)}
            value={value.interpolate((v) => v.toFixed())}
            name={name}
            avatar={avatar}
            xMax={xMax}
            key={name}
          ></HiddenAnimatedBar>
        );
      }
      return (
        <AnimatedBar
          x={xScale(0)}
          y={y}
          width={width}
          height={yScale.bandwidth()}
          color={colorScale(name)}
          value={value.interpolate((v) => v.toFixed())}
          name={name}
          avatar={avatar}
          xMax={xMax}
          key={name}
          challenge={challenge}
          username={username}
          category={category}
        ></AnimatedBar>
      );
    });
  }
);

export default RacingBarGroup;
