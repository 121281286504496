import React from "react";

const Categories = ({ categories, category, setCategory }) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          margin: "5px 0 10px 5px",
          boxSizing: "content-box",
        }}
      >
        {categories.map((cat) => (
          <div
            key={cat}
            style={
              category === cat
                ? {
                    color: "white",
                    backgroundColor: "#f85d3b",
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    border: "1px solid #f85d3b",
                    borderRadius: "12px",
                    fontSize: 12,
                    marginLeft: "5px",
                    fontWeight: 600,
                  }
                : {
                    color: "#f85d3b",
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                    border: "1px solid #f85d3b",
                    borderRadius: "12px",
                    fontSize: 12,
                    marginLeft: "5px",
                    fontWeight: 600,
                  }
            }
            onClick={() => setCategory(cat)}
          >
            {cat}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
